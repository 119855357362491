@import '../vars_smart';

* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
  min-width: 320px;
  min-height: 480px;
}

body {
  background-color: $content-bg-color;
}

ul,
li {
  list-style-type: none;
}

img {
  border: 0;
}

a,
span.link {
  color: #3366cc;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

a:hover,
a:focus,
a:active,
a:focus,
span.link:hover {
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

table {
  border-collapse: collapse;
  width: 100%;
  
  tr {
    vertical-align: top;
  }
}

select {
  border: 1px solid gray;
  background: #fff;
}

textarea {
  outline: none;
  resize: none;
  word-wrap: break-word;
}

h1 {
  line-height: 28px;
  padding: 11px 15px;
  font-weight: normal;
  margin: -15px -15px 15px -15px;
  border: 0;
  border-bottom: 1px solid #ccc;
}

.wrap-h1 {
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  
  &.with-webf-box {
    margin-left: -15px;
    margin-right: -15px;
  }
  
  h1 {
    font-size: 22px;
    border-bottom: none;
    margin-bottom: 0;
  }
}

h3 {
  margin-top: 10px;
}

h4 {
  margin: 10px 0 5px;
  color: #555;
}

.separator {
  background-color: #ddd;
  margin: 20px 0;
  height: 1px;
  font-size: 0;
  
  &.xsmall {
    margin: 5px 0;
  }
  
  &.small {
    margin: 10px 0;
  }
}

/*-------------------------*\
    View
\*-------------------------*/

.line {
  margin-bottom: 10px;
}

label.intitule {
  font-weight: bold;
  margin: 0;
}

/*-------------------------*\
    Bootsrap override
\*-------------------------*/

label {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
  height: auto;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 2px;
}

.input-group-addon {
  font-size: 13px;
  padding-top: 3px;
  padding-bottom: 3px;
  min-width: 40px;
}

label:first-child.required span:after {
  content: '*';
  margin-left: 3px;
  color: red;
}

.form-control-static {
  padding-top: 2px;
}

.form-control:focus {
  box-shadow: none;
  border-color: $bg-main-color;
}

.form-horizontal .control-label {
  padding-top: 3px;
}

.form-horizontal .form-group-inline {
  margin-left: 0;
  margin-right: 0;
}

/*-------------------------*\
    Header
\*-------------------------*/

header {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002; // Pour etre au dessus des dropdownmenu (z: 1000) et en dessous des dialogs (1100)
  
  .logo {
    transition-duration: $duration-transition-sidebar;
    display: block;
    width: 180px;
    line-height: 100px;
    float: left;
    position: fixed;
    text-align: center;
    z-index: 1;
    
    img {
      width: 125px;
    }
  }
  
  nav {
    transition-duration: $duration-transition-sidebar;
    
    position: fixed;
    width: 100%;
    min-width: 320px;
    height: auto;
    float: left;
    
    .navbar-nav {
      margin: 53px 0 0;
      float: none;
      text-align: center;
      
      & > li > a {
        padding: 15px;
  
        &.element-menu {
          padding: 8px;
          background-color: #f2891a1a;
          display: none;
        }
      }
      
      li {
        display: inline-block;
        float: none;
        
        &.logout-link {
          float: right;
        }
  
        &.menu-open {
          .element-menu {
            display: block;
          }
        }
      }
    }
  }
  
  .wrap-hamburger {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    height: 105px;
    width: 70px;
    
    .navbar-btn.sidebar-toggle {
      display: block;
      width: 50px;
      height: 40px;
      margin: 33px 10px;
      
      .icon-bar {
        transform: rotate(0);
        transition-duration: $duration-transition-sidebar;
        
        display: block;
        position: absolute;
        width: 33px;
        height: 4px;
        left: 18px;
        
        &:nth-child(2) {
          top: 42px;
        }
        
        &:nth-child(3),
        &:nth-child(4) {
          top: 51px;
        }
        
        &:nth-child(5) {
          top: 60px;
        }
      }
    }
  }
}

//----- Menu -----//

.menu-edl,
.menu-diagnostics {
  li a {
    &:hover {
      background-color: #f2891a !important;
    }
    
    i {
      margin-right: 6px;
    }
  }
}

//----- Header theme -----//

header {
  font-family: $frame-font-family, sans-serif;
  
  a.logo,
  nav {
    font-weight: 600;
    color: $topbar-text-color;
    background-color: $topbar-bg-color;
  }
  
  a.logo {
    font-size: 2em;
  }
  
  nav {
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3);
    font-size: 1.2em;
    
    & > ul.nav > li > a {
      color: $topbar-text-color;
      
      &:hover,
      &:focus,
      &.open {
        background-color: transparent;
        color: #f2891a;
      }
      
      &.open {
        border-bottom: 2px solid #f2891a;
      }
    }
  }
  
  .navbar-btn.sidebar-toggle {
    .icon-bar {
      background-color: #4e4e4e;
      border-radius: 3px;
    }
  }
}

/*-------------------------*\
    containers
\*-------------------------*/

.main-container {
  position: relative;
  padding-top: 105px;
  margin-bottom: -1px;
}

.right-side {
  transition-duration: $duration-transition-sidebar;
  
  &.fullsize {
    margin-left: 0;
  }
  
  .content-application {
    padding: 15px;
  }
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 8;
  background-color: transparent;
}

body.sidemenu-open .overlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

@media (min-width: 768px) {
  body.sidemenu-open {
    .right-side {
      margin-left: 180px;
    }
    
    .overlay {
      display: none;
    }
  }
}

@media (min-width: 992px) {
  body:not(.sidemenu-collapsed) {
    .overlay {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  body.sidemenu-open .left-side {
    width: $width-sidebar;
    
    .sidebar-toggle a {
      width: $width-sidebar;
      
      .fa-angle-double-left {
        display: inline;
      }
      
      .fa-angle-double-right {
        display: none;
      }
    }
  }
}

@media (min-width: 992px) {
  body:not(.sidemenu-collapsed) .left-side {
    width: $width-sidebar;
    left: 0;
    
    .sidebar-toggle a {
      width: $width-sidebar;
      
      .fa-angle-double-left {
        display: inline;
      }
      
      .fa-angle-double-right {
        display: none;
      }
    }
  }
}

/*-------------------------*\
    Pagination
\*-------------------------*/

.pagination {
  display: block;
  margin: 10px 0;
  
  & > li > span,
  & > li > a {
    border: none;
    border-radius: 5px !important;
    font-size: 0.9em;
    float: none;
  }
}

/*-------------------------*\
    CRUD
\*-------------------------*/

.list-entities {
  .entity {
    h3 a {
      color: #666;
    }
  }
}

.list-actions {
  margin-bottom: 10px;
}

/*-------------------------*\
    Divers
\*-------------------------*/

.smart-tooltip {
  opacity: 0.9;
  display: inline-block;
  background-color: #555;
  color: #fff;
  padding: 4px 8px;
  
  &.tooltip-down {
    &:before {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      top: -6px;
      border: 6px solid transparent;
      border-top: 0;
      border-bottom: 6px solid #555;
      margin-left: -6px;
    }
  }
  
  &.tooltip-up {
    &:after {
      display: block;
      content: '';
      width: 0;
      height: 0;
      position: absolute;
      left: 50%;
      bottom: -5px;
      border: 6px solid transparent;
      border-top: 6px solid #555;
      border-bottom: 0;
      margin-left: -6px;
    }
  }
}

.smart-alert {
  background-color: $primary-bg-color;
}

.webf-box {
  &.fold {
    .box-body {
      display: none;
    }
  }
  
  & > .box-header.drop {
    cursor: pointer;
  }
}

.smart-date,
.smart-datetime {
  cursor: default !important;
  background-color: #fff !important;
}

.webf-dialog.dialog-loading {
  width: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  
  .wrap-img-dialog-wait {
    width: 120px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    border-radius: 75px;
  }
}

.webf-alert-wrapper {
  z-index: 2000;
}

@keyframes dots {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dots-loading span {
  animation-name: dots;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.dots-loading span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots-loading span:nth-child(3) {
  animation-delay: 0.4s;
}

.webf-button {
  font-size: 1em;
}

.webf-select .select {
  font-size: 0.9em;
}

.webf-list .webf-list-item > a, .webf-list .webf-list-item > label {
  font-size: 0.9em;
}

/*-------------------------*\
    Listes
\*-------------------------*/

.box-list {
  .count {
    font-size: 22px;
    color: black;
  }
  
  .options-affichage {
    label {
      display: inline-block;
      float: left;
      margin-right: 4px;
      text-align: right;
      height: 23px;
      line-height: 23px;
    }
    
    .wrap-select-tri {
      width: 100%;
      margin-right: 10px;
      display: inline-block;
      
      select {
        width: 100%;
      }
      
      .select-tri {
        height: 23px;
        float: left;
        line-height: 15px;
        
        .select {
          .handle-down {
            padding: 1px 5px;
          }
        }
      }
    }
    
    .wrap-spinner-nb-par-page {
      label {
        height: 21px;
        line-height: 21px;
      }
      
      .nb-par-page {
        width: 50px;
        text-align: center;
      }
      
      .webf-spinner-buttons {
        a.webf-spin-btn.up {
          i.webf {
            top: -4px;
          }
        }
        
        a.webf-spin-btn.down {
          i.webf {
            top: -6px;
          }
        }
      }
    }
  }
}

/*----------------------------*\
    Formulaire de recherche
\*----------------------------*/

.form-search {
  margin-bottom: 12px;
  
  .webf-select {
    padding-top: 5px;
    padding-bottom: 4px;
  }
  
  .padding-control {
    padding-bottom: 4px;
  }
  
  .webf-tagsinput {
    line-height: 15px;
    
    & > .webf-tag {
      padding: 4px 5px;
    }
    
    input[type='text'] {
      font-size: 12px;
    }
  }
  
  .form-group {
    margin-bottom: 4px;
  }
  
  .row-checkbox {
    height: 28px;
    
    .control-label {
      padding-top: 6px;
    }
  }
}

.webf-checkboxwrapper {
  position: relative;
  top: -1px; // dans les recherches c'est décalé sinon, dans la saisie des infos DPE aussi
}

.checkbox-type .webf-checkboxwrapper {
  top: 2px; // commande EDL sans ça, ça décale vers le haut
}

.wrap-recherche-adresse {
  margin-bottom: 22px;
}

/*------------------*\
    Progress bars
\*------------------*/
.photo .progressbar {
  display: inline-block;
  vertical-align: middle;
  height: 6px;
  width: 100%;
  
  &.progress-photo {
    width: 85%;
  }
}

/*---------*\
    Maps
\*---------*/
.dialog-map {
  .content-dialog-map {
    width: 100%;
    
    .map {
      height: 380px;
      width: auto;
    }
  }
}

@media (min-width: 991px) {
  .content-dialog-map {
    width: 700px;
  }
}

@media (max-width: 767px) {
  .wrap-h1 {
    .button-back, h1 {
      text-align: center;
    }
  
    .button-back {
      padding-bottom: 15px;
    }
  }
}

/*------------------*\
 Media Queries Header
\*------------------*/

@media (min-width: 992px) {
  nav {
    padding-left: 180px;
  }
}

@media (max-width: 992px) {
  header {
    .logo {
      width: 100%;
      position: relative;
    }
    
    nav {
      height: auto;
      padding-left: 0;
      position: relative;
      
      ul.navbar-nav {
        margin: 0;
        float: none;
        text-align: center;
        
        li {
          float: none;
          display: inline-block;
          
          a {
            font-size: 15px;
          }
          
          &.logout-link {
            float: none;
          }
        }
      }
    }
  }
  
  .main-container {
    padding-top: 0;
  }
}

@media (min-width: 768px) {
  header {
    .logo {
      left: 0;
    }
    
    nav {
      .navbar-nav.navbar-right:last-child {
        margin-right: 0;
      }
      
      ul.nav > li > a.toggle-user-menu .intitule {
        display: inline;
      }
  
      ul.navbar-nav {
        li.menu-open {
          a.element-menu {
            display: none;
          }
        }
      }
    }
    
    .wrap-hamburger {
      display: none;
    }
  }
}

@media (max-width: 767px) {
  header {
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.3);
    
    .logo {
      height: 105px;
    }
    
    nav {
      display: none;
    }
  }
  
  body.sidemenu-open {
    .navbar-btn.sidebar-toggle .icon-bar {
      &:nth-child(2) {
        top: 14px;
        width: 0;
        left: 50%;
      }
      
      &:nth-child(3) {
        transform: rotate(45deg);
      }
      
      &:nth-child(4) {
        transform: rotate(-45deg);
      }
      
      &:nth-child(5) {
        top: 14px;
        width: 0;
        left: 50%;
      }
    }
    
    nav {
      display: block;
      height: auto;
      
      ul.navbar-nav {
        li {
          display: block;
        }
      }
    }
  }
}
